import { faEllipsisV, faExclamationCircle, faMinusCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {
  DataGrid,
  useGridApiRef,
  GRID_DATE_COL_DEF,
  GRID_DATETIME_COL_DEF,
  GridEditInputCell,
  useGridApiContext,
} from '@mui/x-data-grid';
import {
  getCurrentMode,
} from '@origino-io/fieldvalidations';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobalContext from '../../Hooks/useGlobalContext';

import TableCell from '../../Layout/components/verificacion/TableCell';

import { ApiFetch } from '../../Utils/ApiFetch';
import { handleDefaultValues } from '../../Utils/gridDefaultValues';

import {
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getLocale } from '../../Utils/locale';

import { formatISO } from 'date-fns';
import Loading from "../../Components/Generic/Loading"
import moment from 'moment';

import { withStyles } from '@material-ui/core';
import {selectAsset35StatusMassive} from '../../Helpers/selectAsset35Status';

import { validateGridWithFiles } from '../../services/fieldsValidations';

const GridMassiveEdit = ({
  data,
  enableEventDate,
  entityMode,
  eventType,
  excelUploadId,
  filterAttributeModel,
  globalModel,
  manualUpload,
  mode,
  onDataChange,
  onSetAmountErrorsInitial,
  onSubmit,
  pkObjectType,
  handleCancel,
  temporalIds,
  setTemporalIds,
  ...props
}) => {
  const { t } = useTranslation();
  const apiGridRef = useGridApiRef();
  const globalContext = useGlobalContext();
  const [amountErrors, setAmountErrors] = useState(0);
  const [fields, setFields] = useState([]);
  const [assets, setAssets] = useState([]);
  const [rowsToAdd, setRowsToAdd] = useState(1);
  const [assetInit, setAssetsInit] = useState([]);
  const [errorsId, setErrorsId] = useState([]);
  const [editableFields, setEditableFields] = useState({});
  const [errors, setErrors] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pairColumns, setPairColumns] = useState([]);
  const [viewOnlyErrors] = useState(false);
  const [fullDataPreFilter] = useState([]);
  const [errorsPreFilter] = useState([]);
  const [uniqueValues, setUniqueValues] = useState([
    { duplicates: [], tuples: [] },
  ]);

  const modelLabels = JSON.parse(localStorage.getItem('SESSION_DATA'))?.models[globalModel].alterLabel?.filter(label =>
    label.event === eventType)[0]?.labels

  const [isWait] = useState(false);
  const {
    userData,
    setHeaderSubtitle,
    selectedBox,
    selectedRowGridMassiveEdit,
    calculateActualQuota,
    selectedCompany,
    selectedAsset,
    relationUserEntity
  } = useGlobalContext();
  const [isError, setIsError] = useState(true);
  const [openReplaceDialog, setOpenReplaceDialog] = useState(false);
  const [dataLastReplacedValue, setDataLastReplacedValue] = useState({});
  const [readyToProcess, setReadyToProcess] = useState(false);

  const apiRef = useRef();

  const assetContext = userData.userData.userData.asset_context;
  const [parentType] = useState(assetContext.parentType);
  const [parentTypeSubType] = useState(
    selectedBox.subtype
  );

  const [alertInfo, setAlertInfo] = useState({
    show: false,
    alertText: ''
  })

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuActionSelectedRow, setMenuActionSelectedRow] = useState(null);
  const openMenu = Boolean(anchorEl);

  const locale = getLocale(userData?.userData?.locale);
  // Check that the error has been fixed to return true. Now its fake.
  const isFixed = (params) => false;

  const rowWithDefaults = (item, fields, pairColumns) => {
    // first convert all the fields keys to an excelName format
    let defaultedObj = fields
      .map((field) => field.name)
      .reduce(
        (a, v) => ({ ...a, [convertColumnOriginToExcelName(v)]: '' }),
        {}
      );
    // merge data
    defaultedObj = { ...defaultedObj, ...item };
    // handle default values
    Object.entries(defaultedObj).forEach((item) => {
      const [key, value] = item;
      const currentField = fields.find(
        (item) => item.name === convertExcelNameToColumnOrigin(key, pairColumns)
      );
      if (key !== 'id') {
        defaultedObj[key] = handleDefaultValues(
          value,
          currentField?.type,
          value,
          currentField
        );
      } else {
        defaultedObj[key] = value;
      }
      if (key === 'specie') {
        defaultedObj[key] = currentField?.defaultValue
      }
      if (key === 'aliment') {
        defaultedObj[key] = value?.split(',')
      }
      if (key === '_eventDate_') {
        defaultedObj[key] = moment()
      }
    });
    const finalDefaultedObj = Object.entries(defaultedObj).reduce(
      (group, currentObj) => {
        const [key, value] = currentObj;
        const originKey = convertExcelNameToColumnOrigin(key, pairColumns);
        let originData = {
          ...group,
        };
        originData[originKey] = value;
        return originData;
      },
      {}
    );
    return finalDefaultedObj;
  };

  const fillSelectTypeValuesFromModel = (models, rowsExcelMapped) => {
    let ret = [];
    rowsExcelMapped.map((row) => {
      let retRow = { ...row };
      Object.entries(row).forEach((item) => {
        const itemField = models.find((x) => x.name === item[0]);
        if (itemField) {
          if (itemField.type === 'select') {
            //todo busco valor del select
            const selelectItem = itemField.values.find((x) => {
              try {
                return x.label.toUpperCase().trim() === row[itemField.name]?.toUpperCase().trim()
              } catch (error) {
                return false
              }
            });
            if (selelectItem) {
              retRow[itemField.name] = selelectItem.key;
            } else {
              retRow[itemField.name] = row[itemField.name];
            }
          } else {
            retRow[itemField.name] = row[itemField.name];
          }
        }
      });

      ret.push(retRow);
    });

    return ret;
  };

  const fetchExcelData = async (models) => {
    const excelUploadid = excelUploadId || -1;

    await ApiFetch(
      `parseExcel/${excelUploadid}`,
      globalContext,
      true,
      async (result) => {
        if (result.status === 200) {
          let resJson = await result.json();
          // add a column ID (TODO: check is column "ID" and if its unique already exist)
          let dataRest = resJson.data.map((item, index) => {
            let finalData = {};
            if (models.length > 0 && resJson.paircolumns.length > 0) {
              finalData = rowWithDefaults(item, models, resJson.paircolumns);
              setReadyToProcess(true);
            } else {
              finalData = item;
            }
            if (finalData.id == null) {
              return {
                ...finalData,
                id: `fake-${index}`,
              };
            } else {
              return {
                ...finalData,
              };
            }
          });
          const t = fillSelectTypeValuesFromModel(models, dataRest);
          setAssets(t);
          setPairColumns(resJson.paircolumns);
        }
      },
      async (error) => {
        console.error('Error:', error, error.message);
      },
      {
        method: 'GET',
      }
    )
  };

  // funcion que se encarga de ordenar los campos que se visualizan en el grid edit ( que se usa en carga masiva)
  function sortAttributes(arrayAttributes, filterAttributeModel) {
    // primero se colocan lso atributos del evento fecha y comentario que comeinzan con el prefijo _
    const array1 = arrayAttributes.filter(item => item.name.startsWith('_'));

    // luego se ponen los atributos propios del evento que llegan como props en filterAttributeModel
    const array2 = (filterAttributeModel) ?
      arrayAttributes.filter(item => filterAttributeModel.some(value => value.name === item.name))
      : [];

    // por ultimo se ponen los demas campos del asset
    const array3 = arrayAttributes.filter(item => !item.name.startsWith('_') && !(filterAttributeModel?.some(value => value.name === item.name)));

    return [...array1, ...array2, ...array3];
  }

  const getModels = () => {
    let models = globalContext.getSessionDataByKey('models');
    let model = globalModel;

    let reqAtributesTemp = [];
    for (let attrTemp of models[model].attributes) {
      let t = { ...attrTemp };
      t.validations = { ...t?.validations };
      reqAtributesTemp.push(t);
    }

    let reqAtributtes = [];
    if (entityMode === 'eventAsset') {
      let attributesName = [];

      for (let event of models[model].events) {
        if (event.eventType === eventType) {
          for (let attributeTemp of event.fields) {
            attributesName.push(attributeTemp.name);
          }
        }
      }

      reqAtributtes = reqAtributesTemp.filter(
        (v) => attributesName.includes(v.name) && v.massiveImport === true
      );
      reqAtributtes.map((attr) => {
        attr.validations.mandatory = { value: true, message: 'mandatory' };
        return attr;
      });

      reqAtributtes = [
        {
          name: '_pk_',
          massiveImport: true,
          modelType: 'header',
          validations: {
            mandatory: { value: true, message: 'mandatory' },
          },
          permissions: {},
          label: 'id',
          type: 'number',
        },
      ].concat(reqAtributtes);
    }

    if (entityMode === 'asset') {
      reqAtributtes = reqAtributesTemp;
    }

    if (enableEventDate && (models[model].eventWithCommentAndDate ?? true)) {
      reqAtributtes = [
        {
          name: '_eventDate_',
          massiveImport: true,
          modelType: 'metadata',
          validations: {
            mandatory: { value: true, message: 'mandatory' },
            max: {
              value: 0,
              unit: 'day',
              message: `La fecha no puede ser posterior a HOY`,
            },
          },
          permissions: {},
          label: 'Fecha del Evento',
          type: 'date',
        },

        {
          name: '_eventComment_',
          massiveImport: true,
          modelType: 'metadata',
          validations: {
            mandatory: { value: false, message: 'mandatory' },
          },
          permissions: {},
          label: 'Comentarios',
          type: 'text',
        },
      ].concat(reqAtributtes);
    }

    if (filterAttributeModel?.length > 0) {
      for (let filterField of filterAttributeModel) {
        for (let attr of reqAtributtes) {
          if (attr.name === filterField.name) {
            if(filterField.disabled) {
              attr.disabled = true
              attr.validations.mandatory = {}
            } else if (filterField.disabled) {
              if (!models[model].events[eventType]?.inheritMandatoryOfAttribute || attr.validations?.mandatory) {
                attr.validations.mandatory = { value: true, message: 'mandatory' };
              }
              attr.disabled = false
            }
          }
        }
      }
    }

    reqAtributtes = sortAttributes(reqAtributtes, filterAttributeModel);
    return reqAtributtes;
  };

  const convertColumnOriginToExcelName = (colName) => {
    const data = pairColumns?.find(
      (ele) => ele?.originColumn?.toLowerCase() === colName.toLowerCase()
    );

    if (data !== undefined) {
      return data.excelColumn;
    } else return colName;
  };

  const validateRows = async () => {
    let ret = 0;
    try {
      const currentMode = getCurrentMode(mode);
      const modeOptions =
        (currentMode === 'update') & (excelUploadId === -1) ? 'Edit' : 'Add';
      const holder = selectedBox.id;
      const options = {
        objectType: entityMode,
        pkObjectType: pkObjectType,
        globalModel: globalModel,
        context: globalContext,
        mode: modeOptions,
        previusUniques: uniqueValues,
        editableFields,
        holder,
      };

      const fieldNames = columns.map(column => column.field);
      const fieldsFiltered = [...fields.filter(item =>  fieldNames.includes(item.name))]

      const trueFields = Object.keys(editableFields).filter(key => editableFields[key] === true);
      const fieldToValidate = fields.filter(item => Object.keys(trueFields).includes(item.name) )

      let {listErrorsId, resultErrors, errorCount, resultUniques} =
        await validateGridWithFiles(assets, fieldToValidate, errors, fieldsFiltered, options);

      setErrorsId(listErrorsId);
      setErrors(resultErrors);
      setUniqueValues(resultUniques);
      ret = errorCount;
    } catch (error) {
      console.log('an error occurs... validateRows', error);
    } finally {
      return ret;
    }
  };

  const checkErrors = async () => {
    //TODO: esto lo puse porque tiene un delay entre que carga los fields assets y pairColumns
    // evita que se vea todas las columnas con error, quisas convendria poner un spinner de loading
    // hasta que esten los tres arrays y luego renderizar y chequear todo
    if (mode === 'Edit') {
      if (fields.length > 0 && assets.length > 0 && readyToProcess) {
        let numErrors = await validateRows();
        setIsError(numErrors > 0);
        setAmountErrors(numErrors);
        onSetAmountErrorsInitial(numErrors);
      }
    } else {
      if (
        fields.length > 0 &&
        assets.length > 0 &&
        pairColumns.length > 0 &&
        readyToProcess
      ) {
        let numErrors = await validateRows();
        setIsError(numErrors > 0);
        setAmountErrors(numErrors);
        onSetAmountErrorsInitial(numErrors);
      }
    }
  };

  const handleChangeValue = (fieldValue, id, fieldName, rowId) => {
    //TODO: se utiliza el asset en ves del getRows para tener el estado anterio al actualizar la cell para comparar
    let index = assets.findIndex((data) => data.id === id);
    if (index === -1) {
      const newAssets = [...assets];
      const newRow = rowWithDefaults({}, fields, pairColumns);
      newRow.id = id;
      newAssets.push(newRow);
      
      setAssets(newAssets);
      onDataChange && onDataChange(newAssets, pairColumns);
    } else if (assets[index][fieldName] !== fieldValue) {
      const newAssets = [...assets];
      newAssets[index][fieldName] = fieldValue;
      setAssets(newAssets);
      onDataChange && onDataChange(newAssets, pairColumns);
    }
  };
  const showReplaceDialog = (params) => {
    let show = apiRef.current.getRowModels().size > 1;
    //let status = { ...openReplaceDialogFieldStatus };
    let status = JSON.parse(localStorage.getItem('openReplaceDialogFieldStatus'));
    if (status.hasOwnProperty(params.field) && show) {
      if (status[params.field] === false) {
        show = false;
      }
    }
    setOpenReplaceDialog(show)
  }

  useEffect(() => {
    let models = globalContext.getSessionDataByKey('models');
    let model = selectedAsset.id;
    const fields = models[model].events.find(event => event.eventType === eventType)?.fields

    if(fields) {
      const columnsInModel = getModels();

      let cols = columnsInModel.map((col) => {
        if (
          !col.massiveImport ||
          (col.onlyEvent && !eventType) ||
          (col.onlyEvent && !!eventType && !(filterAttributeModel.some(a => a.name === col.name))) ||
          !fields.some(field => field.name === col.name)
        ) {
          return null;
        } else {
          return col
        }
      }).filter((dCol) => (dCol !== null && dCol.type === 'detail'))

      let arrayTemporalIds = temporalIds ? [...temporalIds] : []
      for (let j = 0; j < cols.length; j++) {
        for (let i = 0; i < data.length; i++) {
          if(!temporalIds.some(asset => asset.assetId === data[i].id && cols.some(col => col.name === asset.name))) {
            const id = (new Date().valueOf() + i ) * -1
            arrayTemporalIds.push({assetId: data[i].id, name: cols[j].name, id})

            setTemporalIds(arrayTemporalIds)
          }
        }
      }
    }
  }, [])


  const handleRenderCell = (
    params,
    col,
    dataType,
    iconDialog = false,
    isEditable = true
  ) => {
    apiRef.current = params.api;

    const currentErrors = errors.filter(
      (item) =>
        item.row === params.api.getRowIndex(params.row.id) &&
        item.column === col.name
    );

    const temporalPk = temporalIds?.find(asset => asset.assetId === params.id && col.name === asset.name)

    return (
      <TableCell
        {...(isEditable && {
          onChangeValue: handleChangeValue
        })}
        isFixed={isFixed}
        params={params}
        dataType={dataType}
        iconDialog={iconDialog}
        showReplaceDialog={showReplaceDialog}
        setDataLastReplacedValue={setDataLastReplacedValue}
        columnJSON={col}
        isEditable={isEditable}
        globalModel={globalModel}
        entityMode={entityMode}
        pkObjectType={pkObjectType}
        mode={mode}
        errors={currentErrors}
        temporalPk={temporalPk ?? -1}
      />
    );
  };

  const convertExcelNameToColumnOrigin = (colName, excelColumns) => {
    const columns = pairColumns.length !== 0 ? pairColumns : excelColumns;

    const data = columns?.find(
      (ele) => ele?.excelColumn?.toLowerCase() === colName.toLowerCase()
    );

    if (data !== undefined) {
      return data.originColumn;
    } else return colName;
  };

  const validatePermisions = (field) => {
    let isEditable = true;
    if (field.type === 'checkbox') {
      isEditable = true;
    }
    let editable = true;
    let listable = true;
    if (
      field.permissions?.edit[parentType]?.includes(
        parseInt(parentTypeSubType)
      ) === true
    ) {
      editable = true;
    } else {
      editable = false;
    }
    if (
      field.permissions?.list[parentType]?.includes(
        parseInt(parentTypeSubType)
      ) === true
    ) {
      listable = true;
    } else {
      listable = false;
    }
    isEditable = listable && editable;
    return isEditable;
  };

  const isEditableField = (name, field) => {
    let isEditable = true;
    if (
      name === '_eventDate_' ||
      name === '_eventComment_' ||
      name === '_pk_'
    ) {
      if (enableEventDate) isEditable = true;
    } else {
      if (filterAttributeModel?.length > 0) {
        const exist = filterAttributeModel.find(
          (attrFilter) => attrFilter.name === name
        );
        isEditable = exist ? validatePermisions(field) : false;
      } else {
        isEditable = validatePermisions(field);
      }
    }

    return isEditable;
  };

  const rowMenuActionshandleClick = (event, row) => {
    setMenuActionSelectedRow(row);
    setAnchorEl(event.currentTarget);
  }

  const renderActionButtons = (row) => (
    <div
      id="basic-button"
      aria-controls="basic-menu"
      aria-label="More"
      style={{ width: '60px', textAlign: 'center' }}
      onClick={(event) => rowMenuActionshandleClick(event, row)}>
      <FontAwesomeIcon style={{ fontSize: '25px' }} icon={faEllipsisV} width={'23px'} />
    </div>
  );

  function renderEditCell(props) {
    const { error } = props;
    const ErrorToolTip = withStyles({
      tooltip: {
        color: "white",
        backgroundColor: '#ff0000'
      }
    })(Tooltip);
    return (
      <ErrorToolTip open={!!error} title={error || ''}>
        <GridEditInputCell {...props} />
      </ErrorToolTip>
    );
  }

  function CustomFilterInputMultipleSelect(props) {
    const { item, applyValue, type, focusElementRef } = props;
    return (
      <TextField
        id={`contains-input-${item.id}`}
        value={item.value}
        onChange={(event) => applyValue({ ...item, value: event.target.value })}
        type={type || "text"}
        variant="standard"
        InputLabelProps={{
          shrink: true
        }}
        inputRef={focusElementRef}
        select
        SelectProps={{
          native: true
        }}
      >
        {[...props.colDef.valueOptions].map((option) => (
          <option key={option.key} value={option.label}>
            {option.label}
          </option>
        ))}
      </TextField>
    );
  }

  function CustomEditComponent(props) {
    const { id, value, field, row } = props;
    const apiRef = useGridApiContext();
    const handleChange = (event) => {
      const eventValue = event.target.value; // The new value entered by the user
      const newValue =
        typeof eventValue === "string" ? value.split(",") : eventValue;
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newValue.filter((x) => x !== "")
      });
    };
    const opciones = props.colDef.valueOptions(row.productive_system)
    return (
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={!value ? [] : value}
        onChange={handleChange}
        sx={{ width: "100%" }}
      >
        {opciones?.map((option) => (
          <MenuItem key={option.key} value={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  }

  const generateColumns = () => {
    let fieldsGrid = fields

    let cols = fieldsGrid.map((col) => {
      if (
        !col.massiveImport ||
        (col.onlyEvent && !eventType) ||
        (col.onlyEvent && !!eventType && !(filterAttributeModel.some(a => a.name === col.name)))
      ) {
        return '';
      }

      const isEditable = (editableFields[col.name] && !col.disabled) ?? false;
      let field = undefined;
      let width = 200;
      let iconDialog = false;
      let mandatory = !!(col.validations && col.validations?.mandatory?.value);
      if (col.name !== '') field = col.name;

      const labelFiltered = modelLabels?.filter(eventLabel => eventLabel.field === col.name)[0]
      const labelField = !!labelFiltered ? labelFiltered.label : col.label

      let headerName = labelField.toString();
      if (mandatory) headerName += '*';

      const extraProps =
        col.type === 'date'
          ? GRID_DATE_COL_DEF
          : col.type === 'dateTime'
            ? GRID_DATETIME_COL_DEF
            : {};

      const colProps = {
        ...extraProps,
        field: field,
        resizable: false,
        width: width,
        headerName: headerName,
        editable: isEditable,
        //hide: !isEditable, //TODO: opcion para hacer hide las cols
      };
      colProps.cellClassName = (params) => {
        if (!isEditable) {
          return 'super-app-theme--cell disabled'
        } else {
          if ((!params?.value && mandatory) || (col.type === 'file' && params.value === null && mandatory)) {
            return 'super-app-theme--cell error'
          } else {
            const errorsMatch = errors.filter((error) => params.id.at(-1) === error.row.toString())

            const errorFund = col.type !== 'masked' 
              ? errorsMatch.find(error => error.column === colProps.field) 
              : (params.value && !uniqueValues[0]?.tuples?.find(ui => params.value === ui.uniqueVal)?.canUse)

            return errorFund ? 'super-app-theme--cell error' : 'super-app-theme--cell okay'
          }
        }
      }
      switch (col.type) {
        case 'select':
          colProps.type = 'singleSelect';
          colProps.valueOptions = (option) => {
            let optionsKeys = [...col.values]

            if (selectedAsset.id === "35" && (eventType === 6 || eventType === 5) && option.field === "asset_status_change") {
              optionsKeys = selectAsset35StatusMassive(optionsKeys, selectedBox, relationUserEntity, assetInit, option, eventType)
            }

            return optionsKeys.map((data) => {
              return { value: data.key, label: data.label };
            })
          };
          colProps.valueFormatter = (option) => {
            return col.values.find((value) => value.key === option.value)?.label
          }
          break;
        case 'multiselect':
          colProps.type = 'singleSelect';
          colProps.valueOptions = (filterField) => col.values.filter((data) => data.fk === filterField)
          colProps.valueFormatter = ({ value }) => (value ? value.join(',') : "");
          colProps.renderEditCell = (params) => <CustomEditComponent {...params} />;
          colProps.filterOperators = [
            {
              value: "contains",
              getApplyFilterFn: (filterItem) => {
                if (filterItem.value == null || filterItem.value === "") {
                  return null;
                }
                return ({ value }) => {
                  return value.some((cellValue) => cellValue === filterItem.value);
                };
              },
              InputComponent: CustomFilterInputMultipleSelect
            }
          ];
          break;
        case 'date':
        case 'dateTime':
          colProps.type = 'date';
          colProps.valueFormatter = ({ value }) => value ? moment(value).format('DD/MM/YYYY') : null
          break;
        case 'checkbox':
          colProps.type = 'boolean';
          colProps.renderCell = (params) => {
            handleRenderCell(params, col, col.type, isEditable);
            if (params.value === 'true' || params.value) {
              return (
                <>
                  <CheckBoxIcon />
                  {col.aditional?.page &&
                    <a href={col.aditional.page} target="_blank" rel="noopener noreferrer">{col.aditional.label}</a>
                  }
                </>
              )
            } else {
              return (
                <>
                  <CheckBoxOutlineBlankIcon />
                  {(col.aditional?.page && col.aditional.label) &&
                    <a href={col.aditional.page} target="_blank" rel="noopener noreferrer">{col.aditional.label}</a>
                  }
                </>
              )
            }
          };
          break;
        case 'text':
          colProps.type = 'string';
          colProps.preProcessEditCellProps = (params) => {
            const hasError = params.props.value.length < 3;
            return { ...params.props, error: hasError };
          }
          colProps.renderEditCell = (params) => renderEditCell(params)
          break;
        case 'masked':
          colProps.type = 'string';
          colProps.preProcessEditCellProps = (params) => {
            const mask = new RegExp(col.validations.masks.value[2].regex)
            const match = mask.test(params.props.value)
            return { 
              ...params.props, 
              error: !match ? 
                `Para editar debe tener el formato de ${col.validations.masks.value[2].example}` 
                : null 
              };
          }
          colProps.renderEditCell = (params) => renderEditCell(params)
          break;
        case 'float':
          colProps.type = 'number';
          colProps.preProcessEditCellProps = (params) => {
            const maxError = params?.props?.value > col?.validations?.max?.value;
            return { ...params.props, error: maxError ? `Debe ser menor a ${col?.validations?.max?.value}` : null };
          }
          colProps.renderEditCell = (params) => renderEditCell(params)
          break;
        case 'number':
          colProps.type = 'number';
          colProps.preProcessEditCellProps = (params) => {
            const maxError = params?.props?.value > col?.validations?.max?.value;
            return { ...params.props, error: maxError ? `Debe ser menor a ${col.validations.max.value}` : null };
          }
          colProps.renderEditCell = (params) => renderEditCell(params)
          break;
        case 'file':
          colProps.type = 'file';
          iconDialog = true;
          colProps.renderCell = (params) =>
            handleRenderCell(params, col, col.type, iconDialog, isEditable);
          break;
        case 'detail':
          colProps.type = 'detail';
          iconDialog = true;
          colProps.renderCell = (params) =>
            handleRenderCell(params, col, col.type, iconDialog, isEditable);
          break;
        // case 'polygon':
        //   colProps.type = 'polygon';
        //   colProps.width = width;
        //   iconDialog = true;
        //   colProps.renderCell = (params) =>
        //     handleRenderCell(params, col, col.type, iconDialog, isEditable);
        //   break;
        case 'location':
          colProps.type = 'location';
          iconDialog = true;
          colProps.renderCell = (params) => 
            handleRenderCell(params, col, col.type, iconDialog, isEditable);
          break;
        case 'color':
        default:
          colProps.type = 'string';
          // colProps.renderCell = (params) =>
          //   handleRenderCell(params, col, col.type, false, isEditable);
          break;
      }

      return colProps;
    });

    if (mode === 'Add') {
      cols.unshift({
        field: 'actions',
        headerName: 'actions',
        minWidth: '30',
        width: '60',
        sortable: false,
        renderCell: (params) => renderActionButtons(params),
        align: 'center'
      })
    } else {
      cols.unshift({
        type: 'string',
        headerName: 'ID',
        field: 'id',
        editable: false,
        sortable: false,
        resizable: true,
        cellClassName: (params) => {
          return 'super-app-theme--cell disabled'
        },
        preProcessEditCellProps: (params) => {
          const hasError = params.props.value.length < 3;
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params) => renderEditCell(params)
      })
    }

    setColumns(cols.filter((dCol) => dCol !== ''));
  };

  const initializeData = (models) => {
    if (!data || data?.length === 0) {
      fetchExcelData(models);
    } else {
      setPairColumns(props.pairColumns);
      if (models.length !== 0) {
        const dataWithDefaults = data.map((item) => {
          let finalData = {};
          finalData = rowWithDefaults(item, models, props.pairColumns);
          setReadyToProcess(true);
          return { ...finalData };
        });
        setAssets(dataWithDefaults);
        setAssetsInit(JSON.parse(JSON.stringify(dataWithDefaults)));

      } else {
        setAssets(data);
      }
    }
  };

  const calcEditablefields = (models) => {
    const editableFields = {};
    models.forEach((field) => {
      editableFields[field.name] = isEditableField(field.name, field);
    });
    setEditableFields(editableFields);
  };

  const loadQuotaModal = async () => {
    const { remainingQuota } = await calculateActualQuota({
      id: selectedCompany.id,
      type: 'asset'
    });
    if (
      (remainingQuota <= 0 || assets.length > remainingQuota) &&
      entityMode === 'asset' &&
      mode === 'Add'
    ) {
      setAlertInfo((current) => ({
        ...current,
        show: true,
        alertText: t('quotaExceeded', { type: t('token') }),
      }));
    } else {
      setAlertInfo((current) => ({
        ...current,
        show: false,
        alertText: '',
      }));
    }
  }

  useEffect(() => {
    try {
      localStorage.setItem('openReplaceDialogFieldStatus', JSON.stringify({}));
      const models = getModels();
      initializeData(models);
      calcEditablefields(models);
      loadQuotaModal();
      setFields(models);
    } catch (error) {
      console.log('error openReplaceDialogFieldStatus ', error);
    } finally {
      setHeaderSubtitle('stepByStep.massive.step2.loadSubtitle');
    }
  }, []);

  useEffect(() => {
    checkErrors().catch((err) => console.log(err));
    loadQuotaModal();
    generateColumns();
  }, [pairColumns, assets]);

  useEffect(() => {
    generateColumns();
  }, [errors, uniqueValues]);

  const handleCloseReplaceDialogYes = () => {

    let { params } = dataLastReplacedValue;

    // propaga tambien en campos anidados hijos
    let models = globalContext.getSessionDataByKey('models');
    let model = globalModel; //userData.userData.userData.asset_context.model;
    let flagOnlySameParent = false;
    let parent = '';
    let selectedRow = '';

    let extraFields = [];
    for (let attrTemp of models[model].attributes) {
      // crea un array con child / TODO: hacerlo recursivo
      if (params.field === attrTemp.fkField) {
        extraFields.push(attrTemp.name);
      }
      // prepara propagacion selectiva a mismo padre
      if (params.field === attrTemp.name) {
        if (attrTemp.fkField) {
          flagOnlySameParent = true;
          parent = attrTemp.fkField;
          selectedRow = selectedRowGridMassiveEdit;
        }
      }
    }

    let dataList = getDataListFromDataGrid();
    let dataListColumnOriginReplaced = [];
    for (let row of dataList) {
      if (flagOnlySameParent === false) {
        row[params.field] = params.value;
      } else {
        // solo propaga a los que comparten el parent
        if (row[parent] === selectedRow[parent]) {
          row[params.field] = params.value;
        }
      }
      dataListColumnOriginReplaced.push(row);

      // reseteo childs
      for (let extraField of extraFields) {
        row[extraField] = '';
      }
    }
    setAssets(dataListColumnOriginReplaced);

    setOpenReplaceDialog(false);
  };

  const handleCloseReplaceDialogNo = () => {
    setOpenReplaceDialog(false);
  };

  const handleCloseReplaceDialogNoMore = () => {
    //let status = { ...openReplaceDialogFieldStatus };
    let status = JSON.parse(localStorage.getItem('openReplaceDialogFieldStatus'));
    let { params } = dataLastReplacedValue;
    status[params.field] = false;

    //setOpenReplaceDialogFieldStatus(status);
    localStorage.setItem('openReplaceDialogFieldStatus', JSON.stringify(status));
    setOpenReplaceDialog(false);
  };

  const handleCloseReplaceDialogNoMoreAll = () => {
    let status = JSON.parse(localStorage.getItem('openReplaceDialogFieldStatus'));
    //let status = { ...openReplaceDialogFieldStatus };

    for (let field of fields) {
      status[field.name] = false;
    }
    for (let field of pairColumns) {
      status[field.excelColumn] = false;
    }

    //setOpenReplaceDialogFieldStatus(status);
    localStorage.setItem('openReplaceDialogFieldStatus', JSON.stringify(status));
    setOpenReplaceDialog(false);
  };

  const getCorrectDataFromErrors = (dataList, dataSearch) => {
    if (errorsPreFilter.findIndex((id) => id === dataSearch.id) !== -1) {
      const data = dataList.find((data) => data.id === dataSearch.id);
      return data;
    } else {
      return dataSearch;
    }
  };

  const getAllDataListmergeFromErrors = (dataList) => {
    return fullDataPreFilter.map((data) => {
      return getCorrectDataFromErrors(dataList, data);
    });
  };

  const handleOnChangeViewOnlyErrors = (event) => {
    let dataList = getDataListFromDataGrid();
    let dataListSorted = [];
    let dataListWithoutErrors = [];
    let dataListWithErrors = [];
    for (let row of dataList) {
      if (errorsId.includes(row.id)) {
        dataListWithErrors.push(row);
      } else {
        dataListWithoutErrors.push(row);
      }
    }
    for (let row of dataListWithErrors) {
      dataListSorted.push(row);
    }
    for (let row of dataListWithoutErrors) {
      dataListSorted.push(row);
    }
    setAssets(dataListSorted);

    return;
  };

  const getDataListFromDataGrid = () => {
    if (mode === 'Add') {
      const dataLists = Array.from(apiRef.current.getRowModels().entries());
      return dataLists.map((data) => data[1]);
    }
    else {
      return assets
    }
  };

  const getFinalDataList = () => {
    let dataLists = getDataListFromDataGrid();
    if (viewOnlyErrors) {
      // Check if errorCheckBox is ON
      dataLists = getAllDataListmergeFromErrors(dataLists);
    }
    //cambia array por string a la hora de procesar
    dataLists.map((datalist) => datalist.aliment ? datalist.aliment = datalist.aliment.join() : '')
    const depuratedDataList = dataLists.map((item) => {
      const result = item;
      const fieldsToClean = fields.filter(
        (field) =>
          field.type === 'date' ||
          field.type === 'dateTime' ||
          field.type === 'file' ||
          field.type === 'id'
      );
      fieldsToClean.forEach((field) => {
        switch (field.type) {
          case 'date':
          case 'dateTime':
            result[field.name] =
              item[field.name] instanceof Date
                ? formatISO(item[field.name])
                : item[field.name];
            break;
          case 'file':
            result[field.name] =
              item[field.name] === 'Cargar Archivo' ? '' : item[field.name];
            break;
          case 'id':
            result[field.name] = item[field.name]?.startsWith('fake-')
              ? null
              : item.id;
            break;
          default:
            result[field.name] = item[field.name];
            break;
        }
      });
      return result;
    });
    return depuratedDataList;
  };

  const handleFinalCheck = (event) => {
    onSubmit(getFinalDataList());
  };

  const addNewBlankRow = () => {
    let dataList = Array.from(apiRef.current.getRowModels().entries());
    dataList = dataList.map((data) => data[1]);
    
    for (let i = 0; i < Number(rowsToAdd); i++) {
      const defaultRow = rowWithDefaults({}, fields, pairColumns);
      defaultRow.id = `fake-${dataList.length}`;
      dataList.push(defaultRow);
    }

    setAssets(dataList);
  };

  const recursiveResetChild = (params, selectedRow) => {
    let models = globalContext.getSessionDataByKey('models');
    let model = globalModel;
    for (let attrTemp of models[model].attributes) {
      if (params.field === attrTemp.fkField) {
        let dataList = getDataListFromDataGrid();
        for (let rowTemp of dataList) {
          if (rowTemp?.id === selectedRow?.id) {
            rowTemp[attrTemp.name] = '';
            setAssets(dataList);
          }
        }
      }
    }
  }

  const handleCellEditCommit = (params, event) => {
    let selectedRow = selectedRowGridMassiveEdit
    recursiveResetChild(params, selectedRow);

    let show = true;
    let status = JSON.parse(localStorage.getItem('openReplaceDialogFieldStatus'));
    if (status.hasOwnProperty(params.field)) {
      if (status[params.field] === false) {
        show = false;
      }
    }

    if (assets.length === 1) {
      show = false;
    }
    handleChangeValue(params.value, params.id, params.field)
    setOpenReplaceDialog(show);
    setDataLastReplacedValue({ params, event });
  };


  const deleteCurrentRow = () => {
    const filteredAssets = assets.filter(
      (asset) => asset.id !== menuActionSelectedRow?.id
    );
    setAnchorEl(null);
    setAssets(filteredAssets);
  }

  return (
    <Grid container spacing={2}>
      {isWait ? (
        <Loading />
      ) : (
        <>
          {alertInfo.show && (
            <Grid
              item
              xl={12}
              xs={12}
              className="d-flex justify-between"
              alignItems="center"
              justifyContent="center">
              <span className="d-flex flex-row align-center">
                <Alert severity="error">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: alertInfo.alertText,
                    }}></div>
                </Alert>
              </span>
            </Grid>
          )}
          <Grid item xs={12} className="d-flex justify-between">
            <span className="d-flex flex-row align-center">
              <Card className="d-flex flex-row align-center pt-1 pb-1 pl-2 pr-4 mr-2">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  size="1x"
                  className="mr-2"
                  color="#FF0505"
                />
                <Typography variant="body1" component="p">
                  {t('stepByStep.massive.spreadsheet.errorsFound')}{' '}
                  <span className="text-red">{amountErrors}</span>
                </Typography>
              </Card>
              <Button
                className="ml-2"
                variant="outlined"
                onClick={handleOnChangeViewOnlyErrors}>
                {t('stepByStep.massive.spreadsheet.onlyErrorRow')}
              </Button>
            </span>
            {manualUpload && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid rgba(118, 204, 129, 0.5);',
                    borderRadius: '5px',
                  }}
                >
                  <TextField
                    id="rows-number"
                    type="number"
                    size="small"
                    disabled={alertInfo.show || mode !== 'Add'}
                    value={rowsToAdd}
                    onChange={(event) => setRowsToAdd(
                      event.target.value < 1 ? 1 : event.target.value > 50 ? 50 : event.target.value
                    )}
                    sx={{
                      width:'75px',
                      '& fieldset': {
                        borderRadius: 0,
                        borderColor: 'transparent',
                      },
                      '& input[type=number]': {
                        '-moz-appearance': 'textfield', // Ocultar flechas en Firefox
                        '-webkit-appearance': 'none',   // Ocultar flechas en Chrome y Safari
                        margin: 0,
                      },
                      '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',  // Ocultar flechas en Chrome y Safari
                        margin: 0,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none', 
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none', 
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none', 
                      },
                      '.MuiInputBase-input': {
                        padding: 0,
                        textAlign: 'center',
                        height: '100%',
                        boxSizing: 'border-box' 
                      }
                    }}
                  />
                  <Button
                    size='small'
                    onClick={addNewBlankRow}
                    disabled={alertInfo.show || mode !== 'Add'}
                    sx={{
                      px:2,
                      borderLeft:'1px solid rgba(118, 204, 129, 0.5)',
                      borderRadius: 0
                    }}
                  >
                    {t('stepByStep.massive.spreadsheet.button.addRow')}
                  </Button>
                </Box>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
              <DataGrid
                columns={columns}
                sx={{
                  boxShadow: 1,
                  border: 1,
                  input: {
                    minHeight: '35px',
                  },
                  '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '&.MuiDataGrid-root': {
                    border: 'none',
                  },
                  '& .super-app-theme--cell': {
                    border: '1px solid #d1d1d1',
                    color: '#176510',
                    cursor: 'initial',
                    fontWeight: '200',
                    height: '95%',
                    justifyContent: 'center',
                    margin: 'auto',
                    textAlign: 'center',
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  },
                  '& .okay': {
                    background: '#f2fcf4',
                  },
                  '& .error': {
                    background: '#fdeded',
                  },
                  '& .disabled': {
                    background: '#eee',
                    color: '#bbb'
                  }
                }}
                editMode='cell'
                density="standard"
                rows={assets}
                pageSize={10}
                rowsPerPageOptions={[20, 30, 50]}
                autoHeight
                onCellEditCommit={handleCellEditCommit}
                ref={apiGridRef}
              />
              <Menu
                id="basic-menu" anchorEl={anchorEl} open={openMenu}
                onClose={() => setAnchorEl(null)} MenuListProps={{ 'aria-labelledby': 'basic-button' }}
              >
                <MenuItem onClick={deleteCurrentRow}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faMinusCircle} />
                  </ListItemIcon>
                  <ListItemText>
                    {t('GridMassiveEdit.dataGrid.menu.delete')}
                  </ListItemText>
                </MenuItem>
              </Menu>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} className="d-flex justify-center" sx={{ justifyContent: 'space-evenly' }}>
            {handleCancel && <Button
              variant="outlined"
              onClick={handleCancel}>
              Atrás
            </Button>}
            <Button
              variant="contained"
              disabled={isError || alertInfo.show}
              onClick={handleFinalCheck}>
              {t('stepByStep.massive.spreadsheet.button.continue')}
            </Button>
          </Grid>
          <Dialog
            open={openReplaceDialog}
            onClose={handleCloseReplaceDialogNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{ textAlign: 'center' }}>
                <h5>{t('stepByStep.massive.spreadsheet.modal.title')}</h5>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container spacing={2}>
                <Grid item xs={12} className="d-flex justify-center">
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleCloseReplaceDialogYes}
                    autoFocus>
                    {t('stepByStep.massive.spreadsheet.modal.yes')}
                  </Button>
                </Grid>
                <Grid item xs={12} className="d-flex justify-center">
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleCloseReplaceDialogNo}>
                    {t('stepByStep.massive.spreadsheet.modal.no')}
                  </Button>
                </Grid>
                <Grid item xs={12} className="d-flex justify-center">
                  <Button
                    size="small"
                    variant="text"
                    onClick={handleCloseReplaceDialogNoMore}>
                    <u>
                      {t('stepByStep.massive.spreadsheet.modal.noMoreThis')}
                    </u>
                  </Button>
                </Grid>
                <Grid item xs={12} className="d-flex justify-center">
                  <Button
                    size="small"
                    variant="text"
                    onClick={handleCloseReplaceDialogNoMoreAll}>
                    <u>{t('stepByStep.massive.spreadsheet.modal.noMoreAll')}</u>
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Grid>
  );
};

export default GridMassiveEdit;
