import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ActivosTable from '../Assets/components/ActivosTable';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TransferredAssets(props) {
  const { t } = useTranslation();
  const { userData, selectedBox, selectedAsset } =
    useGlobalContext();
  const [permissions, setPermissions] = useState([])

  useEffect(() => {
    setPermissions(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id])
  }, [selectedBox.id, selectedAsset.id, userData?.userData?.permissions?.boxes])

  useEffect(() => {
    if (props.location?.state?.toast) {
      let msg = '';
      if (
        props.location.state.toast.action === 'updated' &&
        props.location.state.toast.mode === 'update'
      ) {
        msg = t('breadcrumb.asset.msg.update');
      }
      if (
        props.location.state.toast.action === 'updated' &&
        props.location.state.toast.mode === 'new'
      ) {
        msg = t('breadcrumb.asset.msg.new');
      }
      if (
        props.location.state.toast.action === 'updated_massive' &&
        props.location.state.toast.mode === 'update'
      ) {
        msg = t('breadcrumb.asset.msg.massive_update');
      }
      if (
        props.location.state.toast.action === 'transfer_massive' &&
        props.location.state.toast.mode === 'update'
      ) {
        msg = t('breadcrumb.asset.msg.massive_transfer');
      }
      if (
        props.location.state.toast.action === 'updated_massive' &&
        props.location.state.toast.mode === 'new'
      ) {
        msg = t('breadcrumb.asset.msg.massive_new');
      }
      if (
        props.location.state.toast.action === 'wait_for_massive' &&
        props.location.state.toast.mode === 'new' 
      ) {
        msg = t('breadcrumb.asset.msg.wait_for_massive_new');
      }
      if (
        props.location.state.toast.action === 'wait_for_massive' &&
        props.location.state.toast.mode === 'update'
      ) {
        msg = t('breadcrumb.asset.msg.wait_for_massive_update');
      }
      if (msg !== '') {
        toast.success(msg, {
          className: 'toast-message-body',
          progressClassName: 'toast-message-progress',
          position: 'bottom-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, []);

  return (
    <>
      {
        permissions?.includes("ASSET_LIST")
          ?
          <Grid container spacing={2}>
            <ToastContainer />
            <Grid item xs={12}>
              <ActivosTable statePath='transferredAssets'/>
            </Grid>
          </Grid>
          :
          <Grid container spacing={2}>
            <Grid item xs={12} className="mt-3">
              <h4>{t("breadcrumb.errorMsg.notAllowed")}</h4>
            </Grid>
          </Grid>
      }
    </>
  );
}

export default TransferredAssets;
