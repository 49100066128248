import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress } from '@mui/material';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { ApiFetch } from "../../Utils/ApiFetch";

const countDownTime = 60

export const ReValidateButton = (props) => {

    const globalContext = useGlobalContext();
    const [seconds, setSeconds] = useState(0);
    const [validationIsLoading, setValidationIsLoading] = useState(false);
    const [firstSend, setFirstSend] = useState(true)

    useEffect( ()=>{
        if (seconds > 0 && !props.validationIsLoading) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        }
    },[seconds,props.validationIsLoading] )

    const validateUser = () => {
        setValidationIsLoading(true)
        ApiFetch(
          'users/reSendValidateMail',
          globalContext,
          false,
          (body) => {
            setValidationIsLoading(false)
            setFirstSend(false)
          },
          async (error) => {
            props.setShowError && props.setShowError(true);
            const res = await error.json();
            props.setMsgError && props.setMsgError('reValidationError');
            setValidationIsLoading(false)
            setFirstSend(false)
          },
          {
            method: 'POST',
            body: JSON.stringify({ email: props.email}),
          }
        );
    
    }

    const execute = () =>{
        setSeconds(countDownTime)
        validateUser()
    }

    const label = ()=>{
        return(
            <>
                { props.isLogin && 
                <div>
                    <Typography>{globalContext.t("signUp.validationLabels.infoLabel")}</Typography>
                </div>
                }
                { ( !firstSend || props.labelVisible ) &&
                    <div className="mt-2">
                        <Typography>{globalContext.t("signUp.validationLabels.questionLabel")}</Typography>
                    </div>
                }
            </>
        )
    }

    return(
        <>
            {label()}

            <Box>
                <Button 
                    variant="contained" 
                    size="large" 
                    className="mt-2"
                    onClick={
                        ()=>{ 
                            execute()
                        } } 
                    disabled={ seconds > 0 }
                >
                    { seconds == 0 && !validationIsLoading && <div> Reenviar Mail </div> }
                    { seconds > 0 && validationIsLoading &&
                                <>
                                    <CircularProgress 
                                        style={{ color: "black" }} 
                                        size={14} 
                                        className="mx-2"
                                    />
                                    <div> Reenviar Mail </div>
                                </>
                    }
                    { seconds > 0 && !validationIsLoading && <div> Reenviar Mail ({seconds}) </div> }
                </Button>
            </Box>
        </>
    )

}
