// eslint-disable-next-line import/no-anonymous-default-export
export default (dataObj) => (
                            dataObj.objectType === "asset" && 
                            dataObj.values?.length > 30 && 
                            (
                                (dataObj.action === 'add' && 
                                dataObj.actionContext.context === "massive_file_form" && 
                                dataObj.actionContext.eventType === -1) 
                            ||  (dataObj.action === 'update' && 
                                dataObj.actionContext.context === "massive_manual_form" && 
                                dataObj.actionContext.eventType === 0)
                            )) 
                            ||
                            (
                                dataObj.objectType === "lot" && 
                                dataObj.values > 30 &&
                                dataObj.action === 'update'
                            )