import React, { useState, useEffect } from 'react';
import { Grid, Stack, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faDownload } from '@fortawesome/pro-light-svg-icons';
import ActivosTable from './components/ActivosTable';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  ASSETS_NEW,
  ASSETS_FILE_LOAD
} from '../../constants/paths';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MassiveAsyncModalAccept from '../../Components/Modal/MassiveAsyncModalAccept';

function Activos(props) {
  const { t } = useTranslation();
  let history = useHistory();
  const { userData, selectedBox, selectedAsset } =
    useGlobalContext();
  const globalContext = useGlobalContext();
  const [permissions, setPermissions] = useState([])
  const [asyncModal, setAsyncModal] = useState(true)

  useEffect(() => {
    setPermissions(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id])
  }, [selectedBox.id, selectedAsset.id, userData?.userData?.permissions?.boxes])

  useEffect(() => {
    if (props.location?.state?.toast) {
      let msg = '';
      if (
        props.location.state.toast.action === 'updated' &&
        props.location.state.toast.mode === 'update'
      ) {
        msg = t('breadcrumb.asset.msg.update');
      }
      if (
        props.location.state.toast.action === 'updated' &&
        props.location.state.toast.mode === 'new'
      ) {
        msg = t('breadcrumb.asset.msg.new');
      }
      if (
        props.location.state.toast.action === 'updated_massive' &&
        props.location.state.toast.mode === 'update'
      ) {
        msg = t('breadcrumb.asset.msg.massive_update');
      }
      if (
        props.location.state.toast.action === 'transfer_massive' &&
        props.location.state.toast.mode === 'update'
      ) {
        msg = t('breadcrumb.asset.msg.massive_transfer');
      }
      if (
        props.location.state.toast.action === 'updated_massive' &&
        props.location.state.toast.mode === 'new' 
      ) {
        msg = t('breadcrumb.asset.msg.massive_new');
      }
      if (
        props.location.state.toast.action === 'wait_for_massive' &&
        props.location.state.toast.mode === 'new' 
      ) {
        msg = t('breadcrumb.asset.msg.wait_for_massive_new');
      }
      if (
        props.location.state.toast.action === 'wait_for_massive' &&
        props.location.state.toast.mode === 'update'
      ) {
        msg = t('breadcrumb.asset.msg.wait_for_massive_update');
      }
      if (msg !== '') {
        toast.success(msg, {
          className: 'toast-message-body',
          progressClassName: 'toast-message-progress',
          position: 'bottom-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, []);

  useEffect( ()=>{
    setAsyncModal(props.location.state?.modal?.asyncModal)
  },[props.location.state?.modal?.asyncModal] )

  const newAssetAction = () => {
    let args = {
      pk: null,
      mode: 'new',
      objectTypeSubType: selectedAsset.id,
      globalModel: selectedAsset.id,
      parentType: 'box',
      parentTypeSubType: selectedBox.subtype,
      parentId: selectedBox.id,
    };
    history.push({
      pathname: ASSETS_NEW,
      state: { refererContext: args },
    });
  };

  const massiveLoadAction = () => {
    let models = globalContext.getSessionDataByKey('models');

    if(models[selectedAsset.id].alterAddMassive){
      let args = {
        pk: null,
        mode: 'new_massive',
        objectTypeSubType: selectedAsset.id,
        globalModel: selectedAsset.id,
        parentType: 'box',
        parentTypeSubType: selectedBox.subtype,
        parentId: selectedBox.id,
      };
      history.push({
        pathname: ASSETS_NEW,
        state: { refererContext: args },
      });
    } else {
      history.push({
        pathname: ASSETS_FILE_LOAD,
      });
    }
  };

  return (
    <>
      {
        permissions?.includes("ASSET_LIST")
          ?
          <Grid container spacing={2}>
            <ToastContainer />
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <div>
                    {!process.env.REACT_APP_HIDE_NOT_IMPLEMENTED === 'true' &&
                      <Button
                        sx={{ ml: 2 }}
                        variant="outlined"
                        startIcon={<FontAwesomeIcon icon={faDownload} />}>
                        {t('breadcrumb.asset.button.download')}
                      </Button>
                    }
                    <Button
                      sx={{ ml: 2 }}
                      variant="contained"
                      disabled={!permissions.includes("ASSET_ADD")}
                      onClick={() => {
                        newAssetAction();
                      }}
                      startIcon={<FontAwesomeIcon icon={faPlus} />}>
                      {t('breadcrumb.asset.button.load_asset')}
                    </Button>
                    <Button
                      sx={{ ml: 2 }}
                      variant="contained"
                      disabled={!permissions.includes("ASSET_ADD")}
                      onClick={() => {
                        massiveLoadAction();
                      }}
                      startIcon={<FontAwesomeIcon icon={faPlus} />}>
                      {t('breadcrumb.asset.button.massive_upload')}
                    </Button>
                  </div>
                </Stack>
              </Grid>
              <ActivosTable />
            </Grid>
          </Grid>
          :
          <Grid container spacing={2}>
            <Grid item xs={12} className="mt-3">
              <h4>{t("breadcrumb.errorMsg.notAllowed")}</h4>
            </Grid>
          </Grid>
      }

      <MassiveAsyncModalAccept
        open={asyncModal}
        toggleModal={() => { setAsyncModal(false) }}
        modalText={props.location.state?.modal?.message}
      />

    </>
  );
}

export default Activos;
